<template>
  <div>
    <el-dialog v-bind="$attrs" v-on="$listeners" @open="onOpen" @close="onClose" title="预售设置爱">
      <el-form ref="book" :model="bookParam" :rules="rules" size="mini" label-width="150px">
        <el-form-item label="预售时间" prop="date">
          <el-date-picker type="datetime" v-model="bookParam.date" format="yyyy-MM-dd HH:mm:ss"
            value-format="yyyy-MM-dd HH:mm:ss" :style="{width: '50%'}" placeholder="请选择预售时间" clearable>
          </el-date-picker>
        </el-form-item>
      </el-form>
      <div slot="footer">
        <el-button @click="close">取消</el-button>
        <el-button type="primary" @click="handelConfirm" :loading="loading">确定</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { yushouOrder } from "@/api/orders"
export default {
  inheritAttrs: false,
  components: {},
  props: [],
  data() {
    return {
      bookParam: {
        query: {},
        date: null,
      },
      rules: {
        date: [{
          required: true,
          message: '请选择预售时间',
          trigger: 'change'
        }],
      },
      loading: false,
    }
  },
  computed: {},
  watch: {},
  created() {},
  mounted() {},
  methods: {
    open(query) {
      this.bookParam.query = query
    },
    onOpen() {},
    onClose() {
      this.$refs['book'].resetFields()
    },
    close() {
      this.$emit('update:visible', false)
    },   
    success() {
      this.$emit('success')
    } ,
    handelConfirm() {
      const that = this
      this.$refs['book'].validate(valid => {
        if (!valid) return
        that.loading = true
        yushouOrder(this.bookParam).then(res=>{
          that.loading = false
          if(res.code===200) {
            that.$message.success(res.message)
            that.success()
            that.close()
          } else {
            that.$message.error(res.message)
          }
        }).catch(err=>{
            that.loading = false
        })
      })
    },
  }
}

</script>
<style>
</style>
